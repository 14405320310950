<template>
  <div>
    <ErrorNotFound v-if="!loading && !product" />
    <div class="h-1">
      <ProgressBar v-if="loading" />
    </div>
    <div v-if="product">
      <h1 class="text-headline-l">{{ t('Reviews.product.view.headline', { productName: product.title }) }}</h1>
      <BackLink class="mt-4 mb-6" :to="{ name: 'reviewsProducts' }">{{ t('backToOverview') }}</BackLink>
      <RouterView :product="product" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, provide } from 'vue'
import { useRoleRedirect } from '@/services/roleRedirect'
import { useReviewsProductByIdQuery } from '@/gql/reviews'
import ErrorNotFound from '@/views/ErrorNotFound.vue'
import ProgressBar from '@/ui/ProgressBar.vue'
import BackLink from '@/ui/BackLink.vue'
import { useI18n } from 'vue-i18n'
import { reloadReviewsProductKey } from '@/symbols/reloadReviewsProductKey'
import type { ProductData } from '@/gql/reviews'
import { mapTranslationsArrToObj } from '@/helpers/translationsHelper'

const YES = 'yes'

const props = defineProps<{
  productId: string
}>()

useRoleRedirect('manageReviews')

const { t } = useI18n()
const variables = computed(() => ({ id: props.productId }))

const { loading, result, refetch } = useReviewsProductByIdQuery(variables, { clientId: 'reviews' })

const product = computed<ProductData | undefined>(() => {
  const data = result.value?.manage?.product
  if (!data) {
    return undefined
  }
  return {
    ...data,
    descriptionTranslationsMap: mapTranslationsArrToObj(data.descriptionTranslations),
    shortDescriptionTranslationsMap: mapTranslationsArrToObj(data.shortDescriptionTranslations),
    linkTextTranslationsMap: mapTranslationsArrToObj(data.linkTextTranslations),
    urlTranslationsMap: mapTranslationsArrToObj(data.urlTranslations),
    disclaimerTextTranslationsMap: mapTranslationsArrToObj(data.disclaimerTextTranslations),
    disclaimerUrlTranslationsMap: mapTranslationsArrToObj(data.disclaimerUrlTranslations),
    noPricingPlansHintTranslationsMap: mapTranslationsArrToObj(data.noPricingPlansHintTranslations),
    ctaSecondaryLabelTranslationsMap: mapTranslationsArrToObj(data.ctaSecondaryLabelTranslations),
    ctaSecondaryUrlTranslationsMap: mapTranslationsArrToObj(data.ctaSecondaryUrlTranslations),
    configFlags: {
      heroActive: data.config.heroImageCached === YES,
      heroEditable: data.config.heroImageAdminCalculated === YES,
      ctaPrimaryActive: data.config.ctaPrimaryCached === YES,
      ctaSecondaryActive: data.config.ctaSecondaryCached === YES,
      ctaSecondaryEditable: data.config.ctaSecondaryAdminCalculated === YES,
    },
  }
})

provide(reloadReviewsProductKey, refetch)
</script>
