<template>
  <div class="py-4 px-8 w-full">
    <div v-if="!loading" class="flex">
      <div>
        <div class="flex flex-col items-center">
          <span class="text-sm mb-3">{{ t('EventsLeadsCount.title') }}</span>
          <span class="text-6xl font-extrabold">{{ sumOfLeads }}</span>
          <span class="text-sm mt-3">{{ t('EventsLeadsCount.subtitle', { eventName: rootEventName }) }}</span>
        </div>
      </div>
      <div v-if="false" class="flex justify-center">
        <p>PIE CHART HERE</p>
      </div>
    </div>
    <hr class="my-6" />

    <div v-if="!loading" class="divide-y divide-grey-300">
      <div v-if="hasPartnerCompany && partnerCompanyAppLeadCount && rootEvent">
        <EventLeadsItem
          title="OMR App"
          :starts-at="rootEvent.startsAt"
          :ends-at="rootEvent.endsAt"
          :lead-count="partnerCompanyAppLeadCount"
          :download-url="downloadAppLeadsUrl()"
        />
      </div>
      <div v-for="event in events" :key="event?.id">
        <EventLeadsItem
          v-if="event"
          :title="`${event.name}`"
          :starts-at="event.startsAt"
          :ends-at="event.endsAt"
          :lead-count="event.leadCount"
          :download-url="downloadEventLeadsUrl(event.id)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  useLeadCountsByEventQuery,
  EventTypeEnum,
  useEventBySlugQuery,
  type LeadCountsByEventQuery,
  usePartnerCompanyLeadCountQuery,
} from '@/gql/myomr'
import { type exportFormat, masterClassesLeadsDownloadUrl, partnerCompanyLeadsDownloadUrl } from '@/services/ApiService'
import { toTypeAndId } from '@/helpers/globalIdHelper'
import EventLeadsItem from './EventLeadsItem.vue'
import { useMeStore } from '@/stores'
import { storeToRefs } from 'pinia'

const { locale, t } = useI18n()

const props = defineProps<{
  eventTypes: EventTypeEnum[]
  hasRootEvent: boolean
  slug: string
}>()

const meStore = useMeStore()
const { currentPartnerCompanyId, currentPartnerCompanyGlobalId } = storeToRefs(meStore)
const hasPartnerCompany = computed(() => !!currentPartnerCompanyId.value)

const rootEventSlug = computed(() => (props.hasRootEvent ? props.slug : null))
const rootEventName = computed(() => (props.hasRootEvent ? rootEvent.value?.name : 'Digital Masterclasses'))
const { result, loading: eventsLoading } = useLeadCountsByEventQuery(() => ({ rootEvent: rootEventSlug.value, type: props.eventTypes }))
const events = computed(() => result.value?.events?.nodes || [])

const { result: rootEventResult, loading: rootEventLoading } = useEventBySlugQuery(() => ({ slug: props.slug }), {
  enabled: props.hasRootEvent,
})
const rootEvent = computed(() => rootEventResult.value?.event)

const loading = computed(() => eventsLoading.value || rootEventLoading.value || partnerCompanyLeadCountLoading.value)

type Event = NonNullable<NonNullable<LeadCountsByEventQuery['events']>['nodes']>[0]
const sumOfMasterclassLeads = computed(() =>
  events.value.map((event: Event) => event?.leadCount || 0).reduce((leadCount: number, sum: number) => leadCount + sum, 0),
)

const { result: partnerCompanyLeadCountResult, loading: partnerCompanyLeadCountLoading } = usePartnerCompanyLeadCountQuery(
  () => ({
    mainEventId: props.slug,
    partnerCompanyId: currentPartnerCompanyGlobalId.value as string,
  }),
  { enabled: props.hasRootEvent && hasPartnerCompany.value },
)
const partnerCompanyAppLeadCount = computed(() => partnerCompanyLeadCountResult.value?.leads?.totalCount || 0)

const sumOfLeads = computed(() => sumOfMasterclassLeads.value + partnerCompanyAppLeadCount.value)

function downloadAppLeadsUrl() {
  if (rootEvent.value?.id && hasPartnerCompany.value) {
    const plainEventId = toTypeAndId(rootEvent.value.id)['id'] as string
    return (format: exportFormat) => partnerCompanyLeadsDownloadUrl(plainEventId, locale.value, format)
  } else {
    return () => ''
  }
}
function downloadEventLeadsUrl(eventId: string) {
  const plainEventId = toTypeAndId(eventId)['id'] as string
  return (format: exportFormat) => masterClassesLeadsDownloadUrl(plainEventId, locale.value, format)
}
</script>
