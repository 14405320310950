import type { ReviewsProductSurveyResponseDetailsQuery } from '@/gql/reviews'

export type SurveyResponse = NonNullable<NonNullable<ReviewsProductSurveyResponseDetailsQuery['manage']>['surveyResponse']>

export type SurveyResponseReminder = SurveyResponse['reminders'][number]

export type SurveyResponseReviewReply = NonNullable<SurveyResponse['review']>['reviewReply']

export enum ReviewInteractionType {
  REMINDER = 'reminder',
  REPLY = 'reply',
}
