import { useMeStore } from '@/stores'
import { authority } from '@/config/env'

function downloadUrl(path: string, params: Record<string, string> = {}) {
  const url = new URL(`${authority}${path}`)
  Object.keys(params).forEach((key) => {
    url.searchParams.append(key, params[key])
  })

  const meStore = useMeStore()
  const currentPartnerCompanyId = meStore.currentPartnerCompanyId
  if (currentPartnerCompanyId) url.searchParams.append('partner_company_id', String(currentPartnerCompanyId))

  return url.href
}

export const exportFormats = ['csv', 'xlsx'] as const
export type exportFormat = (typeof exportFormats)[number]

export function masterClassesLeadsDownloadUrl(eventId: string, locale: string, format: exportFormat) {
  return downloadUrl(`${locale}/lead_exports.${format}`, { event_id: String(eventId) })
}

export function partnerCompanyLeadsDownloadUrl(mainEventId: string, locale: string, format: exportFormat) {
  return downloadUrl(`${locale}/lead_exports.${format}`, { main_event_id: String(mainEventId) })
}

export function eventApplicantsDownloadUrl(eventId: string, locale: string, format: exportFormat) {
  return downloadUrl(`${locale}/attendee_exports/${eventId}.${format}`)
}

export function invitationQuotaInviteesDownloadUrl(invitationQuotaId: string, locale: string, format: exportFormat) {
  return downloadUrl(`${locale}/invitee_exports/${invitationQuotaId}.${format}`)
}

export function invitationQuotaTicketsDownloadUrl(invitationQuotaId: string, filter: string, locale: string, format: exportFormat) {
  return downloadUrl(`${locale}/ticket_exports.${format}`, { invitation_quota_id: invitationQuotaId, filter })
}
