import type { RouteRecordRaw } from 'vue-router'

import {
  Booths,
  DashboardSection,
  ErrorNotFound,
  ErrorUnauthorized,
  EventDataProvider,
  EventInvitations,
  EventsDataProvider,
  InvitationDataProvider,
  MyLeads,
  NetworkIssue,
  PartnerCompany,
  ProspectsSection,
  SpeakersDataProvider,
  UsersDataProvider,
} from '@/views'

import {
  BoothDataProvider,
  BoothDetails,
  BoothList,
  CreateTicketModal,
  EditProspectListModal,
  EditTicketModal,
  EventApplications,
  EventLeads,
  InvitationQuotaCsvMappingForm,
  InvitationQuotaCsvUpload,
  InvitationQuotaViewSwitch,
  PartnerCompanyDetails,
  PartnerCompanyForm,
  ProspectEvent,
  ProspectEvents,
  ProspectList,
  ProspectLists,
  ProspectsCatalog,
  SendProspectListInvitationsModal,
  SendProspectTestEmailModal,
  TicketDataProvider,
  TicketDetailsModal,
} from '@/components'

import { EventForm } from '@/components/EventsInvites'
import { OrderExtras } from '@/components/Order'

import { UserList, EditUser, CreateUser } from '@/components/UserManagement'

import { DeleteProspectListModal } from '@/components/Prospects'

import { AttendeeUpload } from '@/components/EventApplications'

import {
  CreateSpeaker,
  EditSpeakerEvents,
  EditSpeaker,
  SpeakerDataProvider,
  SpeakerList,
  SpeakerView,
} from '@/components/SpeakerManagement'
import AuthorizedArea from '@/components/AuthorizedArea.vue'

import ReviewsDataProvider from '@/views/ReviewsDataProvider.vue'
import {
  ReviewsProductDataProvider,
  ReviewsProductsList,
  ReviewsProductView,
  ReviewsProductEdit,
  ReviewsProductDetails,
  ReviewsProductDetailsEdit,
  ReviewsProductDetailsSubheader,
  ReviewsProductAssets,
  ReviewsProductAssetsEdit,
  ReviewsProductAssetsSubheader,
  ReviewsProductGlobalFeatures,
  ReviewsProductGlobalFeaturesEdit,
  ReviewsProductGlobalFeaturesSubheader,
  ReviewsProductPricing,
  ReviewsProductPricingSubheader,
  ReviewsProductPricingEdit,
  ReviewsProductReviewsSubheader,
  ReviewsProductReviews,
  ReviewsProductReviewContainer,
} from '@/components/ReviewsManagement'
import { RouteName, ReviewsRouteTabs } from '@/router/types'
import LandingPage from '@/components/Hygraph/LandingPage.vue'

const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/content/:slug',
    name: 'publicContentPage',
    component: LandingPage,
  },
  {
    path: '/',
    component: AuthorizedArea,
    children: [
      {
        path: '',
        name: 'root',
        redirect: { name: 'dashboard' },
        meta: {
          isPublic: false,
        },
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardSection,
        meta: {
          isPublic: false,
        },
      },
      {
        path: '/users',
        component: UsersDataProvider,
        meta: {
          isPublic: false,
        },
        children: [
          {
            path: '',
            name: 'userList',
            props: true,
            component: UserList,
          },
          {
            path: 'create',
            name: 'createUser',
            props: true,
            component: CreateUser,
          },
          {
            path: ':id/edit',
            name: 'editUser',
            props: true,
            component: EditUser,
          },
        ],
      },
      {
        path: '/booths',
        component: Booths,
        children: [
          {
            path: '',
            name: 'booths',
            props: true,
            component: BoothList,
          },
          {
            path: 'archive',
            name: 'boothsArchive',
            props: true,
            component: BoothList,
          },
          {
            path: ':boothId',
            props: true,
            component: BoothDataProvider,
            children: [
              {
                path: '',
                name: 'boothDetails',
                props: true,
                component: BoothDetails,
              },
              {
                path: 'extras',
                name: 'extras',
                props: true,
                component: OrderExtras,
              },
            ],
          },
        ],
        meta: {
          isPublic: false,
        },
      },
      {
        path: '/speakers',
        component: SpeakersDataProvider,
        children: [
          {
            path: '',
            name: 'speakerList',
            props: true,
            component: SpeakerList,
          },
          {
            path: 'create',
            name: 'createSpeaker',
            props: true,
            component: CreateSpeaker,
          },
          {
            path: ':speakerId',
            props: true,
            component: SpeakerDataProvider,
            children: [
              {
                path: '',
                name: 'viewSpeaker',
                props: true,
                component: SpeakerView,
              },
              {
                path: 'edit',
                name: 'editSpeaker',
                props: true,
                component: EditSpeaker,
              },
              {
                path: 'events',
                name: 'editSpeakerEvents',
                props: true,
                component: EditSpeakerEvents,
              },
            ],
          },
        ],
      },
      {
        path: '/reviews',
        component: ReviewsDataProvider,
        children: [
          {
            path: 'index',
            name: RouteName.ReviewsProducts,
            props: true,
            component: ReviewsProductsList,
          },
          {
            path: ':productId',
            props: true,
            component: ReviewsProductDataProvider,
            children: [
              {
                path: `${ReviewsRouteTabs.Reviews}/:surveyResponseId`,
                name: RouteName.ReviewsProductViewReviewDetails,
                props: true,
                component: ReviewsProductReviewContainer,
                meta: {
                  tabId: ReviewsRouteTabs.Reviews,
                },
              },
              {
                path: '',
                name: RouteName.ReviewsProductView,
                redirect: { name: RouteName.ReviewsProductViewDetails },
                props: true,
                component: ReviewsProductView,
                children: [
                  {
                    path: ReviewsRouteTabs.Details,
                    name: RouteName.ReviewsProductViewDetails,
                    props: true,
                    components: { default: ReviewsProductDetails, subheader: ReviewsProductDetailsSubheader },
                    meta: {
                      tabId: ReviewsRouteTabs.Details,
                    },
                  },
                  {
                    path: ReviewsRouteTabs.GlobalFeatures,
                    name: RouteName.ReviewsProductViewGlobalFeatures,
                    props: true,
                    components: { default: ReviewsProductGlobalFeatures, subheader: ReviewsProductGlobalFeaturesSubheader },
                    meta: {
                      tabId: ReviewsRouteTabs.GlobalFeatures,
                    },
                  },
                  {
                    path: ReviewsRouteTabs.Pricing,
                    name: RouteName.ReviewsProductViewPricing,
                    props: true,
                    components: { default: ReviewsProductPricing, subheader: ReviewsProductPricingSubheader },
                    meta: {
                      tabId: ReviewsRouteTabs.Pricing,
                    },
                  },
                  {
                    path: ReviewsRouteTabs.Assets,
                    name: RouteName.ReviewsProductViewAssets,
                    props: true,
                    components: { default: ReviewsProductAssets, subheader: ReviewsProductAssetsSubheader },
                    meta: {
                      tabId: ReviewsRouteTabs.Assets,
                    },
                  },
                  {
                    path: ReviewsRouteTabs.Reviews,
                    name: RouteName.ReviewsProductViewReviews,
                    props: true,
                    components: { default: ReviewsProductReviews, subheader: ReviewsProductReviewsSubheader },
                    meta: {
                      tabId: ReviewsRouteTabs.Reviews,
                    },
                  },
                ],
              },
              {
                path: 'edit',
                redirect: { name: RouteName.ReviewsProductEditDetails },
                name: RouteName.ReviewsProductEdit,
                props: true,
                component: ReviewsProductEdit,
                children: [
                  {
                    path: ReviewsRouteTabs.Details,
                    name: RouteName.ReviewsProductEditDetails,
                    props: true,
                    component: ReviewsProductDetailsEdit,
                    meta: {
                      tabId: ReviewsRouteTabs.Details,
                    },
                  },
                  {
                    path: ReviewsRouteTabs.GlobalFeatures,
                    name: RouteName.ReviewsProductEditGlobalFeatures,
                    props: true,
                    component: ReviewsProductGlobalFeaturesEdit,
                    meta: {
                      tabId: ReviewsRouteTabs.GlobalFeatures,
                    },
                  },
                  {
                    path: ReviewsRouteTabs.Pricing,
                    name: RouteName.ReviewsProductEditPricing,
                    props: true,
                    component: ReviewsProductPricingEdit,
                    meta: {
                      tabId: ReviewsRouteTabs.Pricing,
                    },
                  },
                  {
                    path: ReviewsRouteTabs.Assets,
                    name: RouteName.ReviewsProductEditAssets,
                    props: true,
                    component: ReviewsProductAssetsEdit,
                    meta: {
                      tabId: ReviewsRouteTabs.Assets,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/tickets/:selector?',
        component: EventsDataProvider,
        name: 'tickets',
        props: true,
        meta: {
          isPublic: false,
        },
      },
      {
        path: '/events/:selector?',
        component: EventsDataProvider,
        name: 'events',
        props: true,
        meta: {
          isPublic: false,
        },
      },
      {
        path: '/events/:eventId/invitations',
        component: EventInvitations,
        name: 'invitations',
        props: true,
        meta: {
          isPublic: false,
        },
      },
      {
        path: '/events/:eventId/edit',
        component: EventForm,
        name: 'eventEdit',
        props: true,
        meta: {
          isPublic: false,
        },
      },
      {
        path: '/event/:eventId',
        component: EventDataProvider,
        name: 'eventView',
        props: true,
        meta: {
          isPublic: false,
        },
        children: [
          {
            path: 'attendees',
            name: 'EventAttendees',
            props: true,
            component: EventApplications,
          },
          {
            path: 'attendees/upload',
            name: 'AttendeeUpload',
            props: true,
            component: AttendeeUpload,
          },
        ],
      },
      {
        path: '/events/invitations/:invitationQuotaId',
        component: InvitationDataProvider,
        props: true,
        children: [
          {
            path: '',
            name: 'invitationQuota',
            props: true,
            component: InvitationQuotaViewSwitch,
            children: [
              {
                path: 'ticket/new',
                props: true,
                name: 'invitationQuotaCreateTicket',
                component: CreateTicketModal,
              },
              {
                path: 'ticket/:ticketId',
                props: true,
                component: TicketDataProvider,
                children: [
                  {
                    path: 'show',
                    name: 'invitationQuotaTicketDetails',
                    props: true,
                    component: TicketDetailsModal,
                  },
                  {
                    path: 'edit',
                    name: 'invitationQuotaEditTicket',
                    props: true,
                    component: EditTicketModal,
                  },
                ],
              },
            ],
          },
          {
            path: 'upload',
            name: 'invitationQuotaCsvUpload',
            props: true,
            component: InvitationQuotaCsvUpload,
          },
          {
            path: 'match/:uploadId',
            name: 'invitationQuotaCsvMappingForm',
            props: true,
            component: InvitationQuotaCsvMappingForm,
          },
        ],
        meta: {
          isPublic: false,
        },
      },
      {
        path: '/partner-company',
        component: PartnerCompany,
        children: [
          {
            path: '',
            name: 'partnerCompany',
            props: true,
            component: PartnerCompanyDetails,
          },
          {
            path: 'edit',
            name: 'partnerCompanyEdit',
            props: true,
            component: PartnerCompanyForm,
          },
        ],
        meta: {
          isPublic: false,
        },
      },
      {
        path: '/content/:slug',
        name: 'contentPage',
        component: LandingPage,
        props: true,
      },
      {
        path: '/leads',
        name: 'leads',
        children: [
          {
            path: 'myleads',
            component: MyLeads,
            name: 'myleads',
            children: [
              {
                path: ':slug',
                name: 'eventLeads',
                props: true,
                component: EventLeads,
              },
            ],
          },
          {
            path: 'prospects',
            component: ProspectsSection,
            props: true,
            children: [
              {
                path: '',
                name: 'prospectEventsIndex',
                redirect: { name: 'prospectEvents', params: { selector: 'current' } },
              },
              {
                path: ':selector(current|archive)',
                name: 'prospectEvents',
                props: true,
                component: ProspectEvents,
              },
              {
                path: ':selector(current|archive)/:slug',
                name: 'prospectEvent',
                props: true,
                component: ProspectEvent,
                children: [
                  {
                    path: 'catalog',
                    name: 'prospectsCatalog',
                    props: true,
                    component: ProspectsCatalog,
                  },
                  {
                    path: 'your-prospects',
                    name: 'prospectLists',
                    props: true,
                    component: ProspectLists,
                    children: [
                      {
                        path: ':listId',
                        name: 'prospectList',
                        props: true,
                        component: ProspectList,
                        children: [
                          {
                            path: 'delete',
                            name: 'deleteProspectList',
                            props: true,
                            component: DeleteProspectListModal,
                          },
                          {
                            path: 'edit',
                            name: 'editProspectList',
                            props: true,
                            component: EditProspectListModal,
                          },
                          {
                            path: 'test',
                            name: 'sendProspectListTest',
                            props: true,
                            component: SendProspectTestEmailModal,
                          },
                          {
                            path: 'send-invitations',
                            name: 'sendProspectListInvitations',
                            props: true,
                            component: SendProspectListInvitationsModal,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            meta: {
              isPublic: false,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/network-issue',
    name: 'network-issue',
    component: NetworkIssue,
    meta: {
      isPublic: true,
    },
  },

  {
    path: '/401',
    name: '401',
    component: ErrorUnauthorized,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: ErrorNotFound,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: '404' },
  },
] as const

export default routes
