<template>
  <ConfirmModal @close-dialog="emit('closeDialog')">
    <template #title>
      <h1 class="text-headline-m p-6 pb-5">{{ title }}</h1>
    </template>

    <template #description>
      <p class="text-body-m px-6 pb-5">
        {{ description }}
      </p>
    </template>

    <template #content>
      <div class="max-h-[550px] px-6 pt-2 overflow-y-scroll scrollbar-hide pb-11">
        <TextInput
          id="email-subject"
          v-model="formData.subject"
          data-test-id="email-subject"
          :error="getValidationErrors(v$.subject)"
          :label="t('EmailInvitationQuota.emailTemplateModal.emailSubject')"
        />
        <TextInput
          id="message-body"
          v-model="formData.message"
          type="textarea"
          data-test-id="email-body"
          :error="getValidationErrors(v$.message)"
          class="mt-4 min-h-[180px]"
          :label="t('EmailInvitationQuota.emailTemplateModal.emailMessage')"
        />
        <div class="pt-4 pb-4">
          <p class="text-body-m pb-3">{{ t('EmailInvitationQuota.emailTemplateModal.placeholderHint') }}</p>
          <p v-for="chipItem of ChipItems" :key="chipItem" class="message-chip" @click="appendChip(chipItem)">{{ chipItem }}</p>
        </div>
        <div class="flex gap-3 mt-4">
          <TextInput
            id="reply-to"
            v-model="formData.replyTo"
            :error="getValidationErrors(v$.replyTo)"
            data-test-id="reply-to"
            :label="t('EmailInvitationQuota.emailTemplateModal.replyTo')"
            :hint="t('EmailInvitationQuota.emailTemplateModal.replyToHint')"
          />
          <TextInput
            id="cc"
            v-model="formData.cc"
            :error="getValidationErrors(v$.cc)"
            :label="t('EmailInvitationQuota.emailTemplateModal.ccEmail')"
            :hint="t('EmailInvitationQuota.emailTemplateModal.ccEmailHint')"
          />
        </div>
      </div>
    </template>

    <template #actions>
      <div class="flex justify-end mt-8 px-6 pb-6">
        <button class="btn-ghost-purple-m uppercase" @click="emit('closeDialog', false)">
          {{ t('EmailInvitationQuota.emailTemplateModal.cancel') }}
        </button>
        <button class="btn-secondary-purple-m uppercase ml-4" data-test-id="sendTestEmail" @click="sendTestEmail">
          {{ t('EmailInvitationQuota.emailTemplateModal.saveAndSendTestEmail') }}
        </button>
        <button class="btn-primary-purple-m ml-4" data-test-id="saveTemplate" @click="saveTemplate(false)">
          {{ t('EmailInvitationQuota.emailTemplateModal.saveTemplate') }}
        </button>
      </div>
    </template>
  </ConfirmModal>
</template>
<script lang="ts" setup>
// Composition api
import { reactive } from 'vue'

// Validators
import { required, email } from '@/services/validators'
import useVuelidate from '@vuelidate/core'

// Helpers
import { handleMutationResponse } from '@/helpers/handleMutationResponseHelper'

// Graphql
import { useSendTestEmailMutation, useEditEmailTemplateMutation } from '@/gql/myomr'

// Types
import type { EditEmailTemplateInput, InvitationQuotaQuery, SendTestEmailInput } from '@/gql/myomr'

import { TextInput } from '@ramp106/omrjs-core-ui'
import { getValidationErrors } from '@/helpers/validationHelper'

// Services
import { useI18n } from 'vue-i18n'
import { showNotification } from '@/helpers/notificationHelper'
const { t } = useI18n()

const { mutate: sendTestEmailMutation } = useSendTestEmailMutation()
const { mutate: editEmailTemplateMutation } = useEditEmailTemplateMutation()

const props = defineProps<{
  title: string
  description: string
  invitationQuota: InvitationQuotaQuery['invitationQuota']
}>()

const emit = defineEmits(['closeDialog'])

// Form management
const formData = reactive({
  subject: props.invitationQuota.messageSubject || '',
  message: props.invitationQuota.messageBody || '',
  replyTo: props.invitationQuota.replyTo || '',
  cc: props.invitationQuota.cc || '',
})

const rules = {
  subject: { required },
  message: { required },
  replyTo: { required, email },
  cc: { email },
}

const v$ = useVuelidate(rules, formData)

// Chips logic
const ChipItems = ['{{title}}', '{{first_name}}', '{{last_name}}', '{{company}}', '{{gender}}']

async function appendChip(chipItem: string) {
  formData.message += chipItem
}

// Mutations
async function saveTemplate(sendTestEmail = false) {
  const isFormValid = await v$.value.$validate()
  if (!isFormValid) {
    if (sendTestEmail) throw 'validation'
    return
  }

  const emailTemplateData: EditEmailTemplateInput = {
    invitationQuotaId: props.invitationQuota.id,
    subject: formData.subject,
    body: formData.message,
    replyTo: formData.replyTo,
  }

  if (formData.cc.length) emailTemplateData.emailCc = formData.cc
  await editEmailTemplateMutation(emailTemplateData)
    .then((result) => {
      if (result?.data?.editEmailTemplate?.errors.length) throw 'server'
      if (!sendTestEmail) {
        handleMutationResponse(
          t,
          result?.data?.editEmailTemplate,
          `${t('EmailInvitationQuota.emailTemplateModal.templateSavedNotification')}`,
        )
        emit('closeDialog')
      }
    })
    .catch((error) => showNotification(`${t('EventForm.failedUpdateNotification')}: ${error.message}`, 'error'))
}

async function sendTestEmail() {
  let templateSaved = false
  try {
    await saveTemplate(true)
    templateSaved = true
  } catch (e) {
    if (e === 'validation') showNotification(`${t('EmailInvitationQuota.emailTemplateModal.templateInvalid')}`, 'error')
    if (e === 'server') showNotification(`${t('EmailInvitationQuota.emailTemplateModal.templateSaveFailedNotification')}`, 'error')
  }
  if (!templateSaved) return
  const testEmailData: SendTestEmailInput = {
    invitationQuotaId: props.invitationQuota.id,
  }
  await sendTestEmailMutation({ ...testEmailData })
    .then((result) => {
      handleMutationResponse(t, result?.data?.sendTestEmail, t('EmailInvitationQuota.sendTestEmail.success', { email: formData.replyTo }))
    })
    .catch((error) => showNotification(`${t('EventForm.failedUpdateNotification')}: ${error.message}`, 'error'))
  emit('closeDialog')
}
</script>
<style lang="scss" scoped>
.message-chip {
  @apply p-2 text-xs bg-grey-100 border border-grey-700 inline-block mr-2 cursor-pointer;
}
</style>
