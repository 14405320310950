<template>
  <div class="flex justify-between items-center w-full pt-4">
    <div>
      <div class="align-baseline text-headline-s">
        {{ name }}
        <div v-if="expiresAt !== null" class="inherit-font-family ml-2 mt-2 small-text" :class="!isExpired ? 'text--secondary' : ''">
          - {{ getExpirationDate }}
        </div>
      </div>
      <div v-show="!open" class="mt-4">
        <div v-if="selectedExtras">{{ t('BoothExtras.selected') }}: {{ selectedExtras }}</div>
        <div v-else>
          {{ t('BoothExtras.nothingSelected') }}
        </div>
      </div>
    </div>
    <span v-show="!open" class="mr-4 text-headline-s">
      {{ formatPrice(selectedExtrasPricing) }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { formatPrice } from '@/helpers/moneyHelper'
import type { ExtraProduct } from './OrderExtras.vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = defineProps<{
  expiresAt: string
  extras: ExtraProduct[]
  name: string
  open: boolean
}>()

const isExpired = computed(() => {
  if (!props.expiresAt) return false
  return new Date(props.expiresAt) < new Date()
})

const getExpirationDate = computed(() => {
  if (!props.expiresAt) {
    return ''
  }
  const date = new Date(props.expiresAt)
  if (!isExpired.value) {
    return t('BoothExtras.availableUntil', {
      date: date
        .toLocaleDateString('de-DE', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        })
        .replace(/[/]/g, '.'),
    })
  }
  return t('BoothExtras.availableBefore', {
    date: date
      .toLocaleDateString('de-DE', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      })
      .replace(/[/]/g, '.'),
  })
})

const selectedExtras = computed(() => {
  return props.extras
    .filter((extra) => extra.count != 0 && extra.selectedVariant)
    .map((extra) => extra.product.name)
    .join(', ')
})

const selectedExtrasPricing = computed(() => {
  return props.extras
    .filter((extra) => extra.count != 0)
    .map((extra) => (extra.selectedVariant?.price || 0) * extra.count + (extra.product.deliveryFee || 0))
    .reduce((sum, p) => sum + p, 0)
})
</script>
<style>
.inherit-font-family {
  font-family: inherit !important;
}
.small-text {
  font-size: 0.86em;
}
</style>
