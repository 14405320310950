<template>
  <div class="py-6">
    <LabelValue :label="t('Reviews.product.view.title.label').toString()">
      <span data-testid="product-title"> {{ product.title }}</span>
    </LabelValue>
    <LabelValue :label="t('Reviews.product.view.logo.label').toString()" data-testid="product-logo">
      <ProductLogo v-if="product.logo" :logo-url="product.logo.url" size="large" />
      <span v-else>{{ t('Reviews.product.view.logo.missing') }}</span>
    </LabelValue>
    <LabelValue :label="t('Reviews.product.view.hero.label').toString()" data-testid="product-hero">
      <div v-if="product.hero" class="max-w-md">
        <AssetPreview
          :content-type="product.hero.contentType"
          :disabled="!product.configFlags.heroActive"
          :name="product.hero.name"
          :url="product.hero.url"
        />
      </div>
      <template v-else>
        <span v-if="product.configFlags.heroActive">{{ t('Reviews.product.view.hero.missing') }}</span>
        <span v-else>{{ t('Reviews.product.view.hero.notActive') }}</span>
      </template>
    </LabelValue>
    <LabelValue :label="t('Reviews.product.view.category.label').toString()" data-testid="product-category">
      <div class="flex gap-2 flex-wrap items-center">
        <ChipBadge
          v-for="(category, index) in productCategories"
          :key="index"
          rounded
          :type="index === 0 ? 'solid' : 'default'"
          color="purple"
          >{{ category }}</ChipBadge
        >
      </div>
      <div class="mt-2 text-grey-800">
        <MdiSvg class="inline-block mr-2" :path="mdiInformationOutline" />{{ t('Reviews.product.view.category.hint') }}
      </div>
    </LabelValue>
    <LabelValue :label="t('Reviews.product.view.shortDescription.label').toString()" data-testid="product-short-desctiption">
      <div class="grid grid-cols-2 gap-x-6">
        <div
          v-for="lang in ALLOWED_LOCALES"
          :key="lang"
          class="flex flex-col items-start gap-y-2"
          :data-testid="'short-description-' + lang"
        >
          <ChipBadge class="uppercase" rounded>{{ lang }}</ChipBadge>
          <span v-if="product.shortDescriptionTranslationsMap[lang]" :data-testid="'short-description-text-' + lang">{{
            product.shortDescriptionTranslationsMap[lang]
          }}</span>
          <span v-else class="text-grey-800">{{ t('Reviews.product.view.shortDescription.missing') }}</span>
        </div>
      </div>
    </LabelValue>
    <LabelValue :label="t('Reviews.product.view.description.label').toString()" data-testid="product-description">
      <Expandable :max-height="247">
        <div class="grid grid-cols-2 gap-x-6">
          <div v-for="lang in ALLOWED_LOCALES" :key="lang" class="flex flex-col items-start gap-y-2" :data-testid="'description-' + lang">
            <ChipBadge class="uppercase" rounded>{{ lang }}</ChipBadge>
            <div>
              <MarkdownContent
                v-if="product.descriptionTranslationsMap[lang]"
                :md="product.descriptionTranslationsMap[lang]"
                :data-testid="'description-text-' + lang"
              />
              <span v-else class="text-grey-800">{{ t('Reviews.product.view.description.missing') }}</span>
            </div>
          </div>
        </div>
      </Expandable>
    </LabelValue>
    <LabelValue :label="t('Reviews.product.view.links.label').toString()" data-testid="product-links">
      <div class="grid grid-cols-2 gap-x-6 gap-y-2">
        <div v-for="lang in ALLOWED_LOCALES" :key="lang">
          <ChipBadge class="uppercase mb-4" rounded>{{ lang }}</ChipBadge>
          <div class="flex items-center">
            <MdiSvg :path="mdiWeb" :size="24" class="social-link-icon" />
            <a
              v-if="product.urlTranslationsMap[lang]"
              class="whitespace-nowrap overflow-hidden text-ellipsis"
              :href="product.urlTranslationsMap[lang]"
              data-testid="product-website"
            >
              {{ product.linkTextTranslationsMap[lang] || product.urlTranslationsMap[lang] }}
            </a>
            <span v-else class="text-grey-800">{{ t('Reviews.product.view.url.missing') }}</span>
          </div>
        </div>
        <div class="flex items-center">
          <MdiSvg :path="mdiLinkedin" :size="24" class="social-link-icon" />
          <a
            v-if="product.linkedin"
            class="whitespace-nowrap overflow-hidden text-ellipsis"
            :href="product.linkedin"
            data-testid="product-linkedin"
          >
            {{ product.linkedin }}
          </a>
          <span v-else class="text-grey-800">{{ t('Reviews.product.view.linkedin.missing') }}</span>
        </div>
        <div />
        <div class="flex items-center">
          <MdiSvg :path="iconTwitterX" :size="24" class="social-link-icon" />
          <a
            v-if="product.twitter"
            class="whitespace-nowrap overflow-hidden text-ellipsis"
            :href="product.twitter"
            data-testid="product-twitter"
          >
            {{ product.twitter }}
          </a>
          <span v-else class="text-grey-800">{{ t('Reviews.product.view.twitter.missing') }}</span>
        </div>
      </div>
    </LabelValue>
    <LabelValue :label="t('Reviews.product.view.contact.label').toString()" data-testid="product-contact">
      <div class="text-body-m-bold pb-2" data-testid="cta-primary-label">
        {{ t('Reviews.product.view.contact.primary.label') }}
      </div>
      <ChipBadge
        v-if="product.configFlags.ctaPrimaryActive"
        rounded
        color="success"
        :prepend-icon="iconDot"
        data-testid="cta-primary-status"
      >
        {{ t('Reviews.status.active') }}
      </ChipBadge>
      <ChipBadge v-else rounded :prepend-icon="iconDotOutline">{{ t('Reviews.status.hidden') }}</ChipBadge>
      <div class="text-body-m-bold mt-6 pb-2" data-testid="cta-secondary-label">
        {{ t('Reviews.product.view.contact.secondary.label') }}
      </div>
      <template v-if="product.configFlags.ctaSecondaryActive">
        <template v-if="product.ctaSecondary">
          <ChipBadge rounded color="success" :prepend-icon="iconDot" data-testid="cta-secondary-status">{{
            t('Reviews.status.active')
          }}</ChipBadge>
          <div class="text-body-s-bold mt-2 mb-1">
            {{ t('Reviews.product.view.contact.secondary.btnLabel') }}
          </div>
          <span data-testid="cta-secondary-button-label">
            {{ product.ctaSecondary.label }}
          </span>
          <div class="text-body-s-bold mt-2 mb-1" data-testid="cta-secondary-button-url">
            {{ t('Reviews.product.view.contact.secondary.btnUrl') }}
          </div>
          <a class="block overflow-hidden text-ellipsis" :href="product.ctaSecondary.url" target="_blank" data-testid="cta-secondary-url">{{
            product.ctaSecondary.url
          }}</a>
        </template>
        <ChipBadge v-else rounded :prepend-icon="iconDotOutline">{{ t('Reviews.status.hidden') }}</ChipBadge>
      </template>
      <div v-else>
        <ChipBadge rounded :prepend-icon="iconDotOutline">{{ t('Reviews.status.notSupported') }}</ChipBadge>
        <div class="text-body-s mt-4" v-html="t('Reviews.product.view.contact.secondary.notSupported').toString()" />
      </div>
    </LabelValue>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { ChipBadge, Expandable, MarkdownContent, MdiSvg, iconTwitterX, iconDot, iconDotOutline } from '@ramp106/omrjs-core-ui'
import { ProductLogo } from '@ramp106/omrjs-reviews-ui'
import { mdiLinkedin, mdiWeb, mdiInformationOutline } from '@mdi/js'
import type { ProductData } from '@/gql/reviews'
import { useI18n } from 'vue-i18n'
import LabelValue from '@/ui/LabelValue.vue'
import AssetPreview from '@/ui/AssetPreview.vue'
import { ALLOWED_LOCALES } from '@/helpers/translationsHelper'

const props = defineProps<{
  product: ProductData
}>()

const { t } = useI18n()

const productCategories = computed(() => {
  const categories = props.product.categories
    .filter((category) => category.published && !category.hidden && category.id !== props.product.mainCategory.id)
    .map((category) => category.title)
  return [`${props.product.mainCategory.title} (${t('Reviews.product.view.category.main')})`, ...categories]
})
</script>

<style scoped>
.social-link-icon {
  @apply flex-shrink-0 mr-1 text-grey-800;
}
</style>
