import { createApp, provide, h } from 'vue'
import router from './router'
import i18n from './plugins/i18n'
import App from './App.vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import FloatingVue from 'floating-vue'
import { ApolloClients } from '@vue/apollo-composable'
import { myomrApolloClient, reviewsApolloClient, hygraphApolloClient } from '@/plugins/vue-apollo'
import RolesMixin from './mixins/rolesMixin'
import FlagIcon from 'vue-flag-icon'
import * as Sentry from '@sentry/vue'
import { createSentryPiniaPlugin } from '@sentry/vue'
import env from '@/config/env'

import '@mdi/font/css/materialdesignicons.css'
import 'floating-vue/dist/style.css'

const app = createApp({
  mixins: [RolesMixin],
  setup() {
    provide(ApolloClients, {
      default: myomrApolloClient,
      reviews: reviewsApolloClient,
      hygraph: hygraphApolloClient,
    })
  },

  render: () => h(App),
})
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
pinia.use(createSentryPiniaPlugin())

function getSampleRate() {
  return {
    environment: 0.0,
    staging: 1.0,
    production: 0.6,
  }[env]
}

Sentry.init({
  app,
  environment: env === 'environment' ? 'development' : env,
  dsn: 'https://74c5198f96a641cd88a1e8b0ff19fc82@o4504020351778816.ingest.sentry.io/4504576367001600',
  integrations: [Sentry.browserTracingIntegration({ router })],

  // trancesSampleRate is set to 1.0 for now, if we get spammed by errors we can reduce this number
  tracePropagationTargets: ['manage.omr.com', 'manage.omr.ninja', /^\//],
  tracesSampleRate: getSampleRate(),
})

app.use(i18n)
app.use(pinia)
app.use(router)
app.use(FloatingVue)
app.use(FlagIcon)
app.mount('#app')
