<template>
  <div class="my-6 flex flex-row justify-between items-center min-h-10">
    <h2 class="text-headline-m flex items-center">
      <span class="pr-2">
        {{ t('Reviews.product.view.reviewDetailTitle', { authorName: authorName || t('Reviews.product.review.history.unknownAuthor') }) }}
      </span>
      <ReviewStatusBadge v-if="state" class="flex" :state="state" :rejected-reason="rejectedReason" />
      <ReviewInteractionStatus
        v-if="remindersCount && remindersCount > 0"
        :type="ReviewInteractionType.REMINDER"
        :tooltip-text="t('Reviews.product.review.reminderStatusHint', { count: remindersCount })"
        class="ml-2"
      />
      <ReviewInteractionStatus v-if="hasReply" :type="ReviewInteractionType.REPLY" class="ml-2" />
    </h2>
    <button v-if="canAddReply" class="btn-primary-purple-m" @click="$emit('send-reply')">
      {{ t('Reviews.product.view.actions.sendReply') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import ReviewStatusBadge from './ReviewStatusBadge.vue'
import { computed } from 'vue'
import { SurveyResponseStateEnum } from '@/gql/reviews'
import ReviewInteractionStatus from '@/components/ReviewsManagement/Reviews/ReviewInteractionStatus.vue'
import { ReviewInteractionType } from './types'

const props = defineProps<{
  state?: string
  authorName?: string
  hasReply?: boolean
  rejectedReason?: string
  remindersCount?: number
}>()

defineEmits(['send-reply'])

const { t } = useI18n()
const canAddReply = computed(() => props.state === SurveyResponseStateEnum.Published && !props.hasReply)
</script>
