<template>
  <div class="px-6 mt-5">
    <div
      class="border-2 relative p-2 h-52 rounded cursor-text transition-all"
      :class="{
        'border-amaranth-600': maxLimitReached,
        'border-grey-300': !maxLimitReached && !isInputFocused,
        'border-victoria-600': !maxLimitReached && isInputFocused,
      }"
      @click="focusOnInput"
    >
      <div class="h-48 overflow-auto">
        <span
          class="absolute bg-white -top-3 left-2 px-1 z-0 transition-all"
          :class="{
            'text-amaranth-600': maxLimitReached,
            'text-victoria-600': !maxLimitReached && isInputFocused,
            'text-grey-400': !maxLimitReached && !isInputFocused,
          }"
        >
          {{ props.label }}
        </span>
        <span
          v-for="(num, index) in ticketNumbers"
          :key="num"
          class="chip text-label-s inline-flex items-center mt-2 mr-1 rounded-full bg-victoria-600 pr-1"
        >
          <span class="mr-1">{{ num }}</span>
          <SvgIcon :path="mdiCloseCircle" class="cursor-pointer" :size="20" @click="removeTicket(index)" />
        </span>
        <input
          v-if="!maxLimitReached"
          ref="chipInput"
          v-model="inputValue"
          type="text"
          class="mt-2 bg-white outline-none w-[135px]"
          @keyup="addItemToList"
          @keydown="removeItemFromList"
          @focus="isInputFocused = true"
          @blur="isInputFocused = false"
          @paste.prevent="handlePaste"
        />
      </div>
    </div>
    <p v-if="maxLimitReached" class="text-xs text-amaranth-600 mt-3">{{ t('attendee.manualAddModal.limitReachedAlert') }}</p>
  </div>
</template>

<script setup lang="ts">
import { nextTick, ref, watch, computed } from 'vue'
import { mdiCloseCircle } from '@mdi/js'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
  label: string
  maxChips?: number | null
}>()

const inputValue = ref('')
const ticketNumbers = ref<string[]>([])
const isInputFocused = ref(false)
const chipInput = ref()

const emit = defineEmits<{
  (e: 'change', value: string[]): void
}>()

const formattedInputValue = computed<string>(() => {
  if (inputValue.value.includes(',')) return inputValue.value.slice(0, -1)
  return inputValue.value
})

const maxLimitReached = computed(() => {
  if (props.maxChips === null || props.maxChips === undefined) return false
  return ticketNumbers.value.length >= props.maxChips
})

function removeTicket(index: number) {
  ticketNumbers.value.splice(index, 1)
}

function handlePaste(event: ClipboardEvent) {
  if (!event.clipboardData || maxLimitReached.value) return
  let pastedItems = event.clipboardData
    .getData('text')
    .replace(/(?:\r\n|\r|\n| )/g, ',')
    .split(',')
    .filter((item) => item !== '')
  const totalTicketLength = pastedItems.length + ticketNumbers.value.length

  if (props.maxChips && totalTicketLength > props.maxChips) pastedItems = pastedItems.slice(0, -(totalTicketLength - props.maxChips))
  ticketNumbers.value.push(...pastedItems)
}

function addItemToList(keyEvent: KeyboardEvent) {
  if (!formattedInputValue.value.length) return
  if (maxLimitReached.value) return
  if (!['enter', ',', ' '].includes(keyEvent.key.toLowerCase())) return
  if (!formattedInputValue.value.trim().length) return
  if (!ticketNumbers.value.includes(formattedInputValue.value)) ticketNumbers.value.push(formattedInputValue.value)
  inputValue.value = ''
}

function removeItemFromList(keyEvent: KeyboardEvent) {
  if (keyEvent.key.toLowerCase() === 'backspace' && ticketNumbers.value.length && !formattedInputValue.value.length)
    ticketNumbers.value.pop()
}

function focusOnInput() {
  if (maxLimitReached.value) return
  nextTick(() => {
    if (chipInput.value) chipInput.value.focus()
  })
}

watch(ticketNumbers.value, (newValue: string[]) => {
  emit('change', newValue)
})
</script>
