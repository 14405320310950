<template>
  <TabBar>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsRouteTabs.Details"
      :to="{ name: RouteName.ReviewsProductViewDetails }"
    >
      {{ t('Reviews.product.view.tab.details') }}
    </NavTabBarItem>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsRouteTabs.GlobalFeatures"
      :to="{ name: RouteName.ReviewsProductViewGlobalFeatures }"
    >
      {{ t('Reviews.product.view.tab.globalFeatures') }}
    </NavTabBarItem>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsRouteTabs.Pricing"
      :to="{ name: RouteName.ReviewsProductViewPricing }"
    >
      {{ t('Reviews.product.view.tab.pricing') }}
    </NavTabBarItem>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsRouteTabs.Assets"
      :to="{ name: RouteName.ReviewsProductViewAssets }"
    >
      {{ t('Reviews.product.view.tab.assets') }}
    </NavTabBarItem>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsRouteTabs.Reviews"
      :to="{ name: RouteName.ReviewsProductViewReviews }"
    >
      {{ t('Reviews.product.view.tab.reviews') }}
    </NavTabBarItem>
  </TabBar>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { TabBar, NavTabBarItem } from '@/ui/Tabs'
import { useI18n } from 'vue-i18n'
import { RouteName, ReviewsRouteTabs } from '@/router/types'

const { t } = useI18n()
const route = useRoute()

const disabled = computed(() => {
  return route.name?.toString().startsWith(RouteName.ReviewsProductEdit)
})
</script>
