import env from '@/config/env'

import * as Sentry from '@sentry/vue'

let errorHandler: {
  report: (message?: any, ...optionalParams: any[]) => void
}

if (['staging', 'production'].includes(env)) {
  errorHandler = {
    report: function (...args) {
      Sentry.captureException(args)
    },
  }
} else {
  errorHandler = { report: console.error }
}

export default errorHandler
