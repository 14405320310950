<template>
  <ContentCard v-if="result?.documents.nodes.length" class="mt-6 pt-8">
    <p v-if="!everythingIsSigned" class="text-body-m">
      {{ t('Documents.openDocumentsNotice') }}
    </p>

    <div v-for="document in result?.documents.nodes" :key="document.id" class="separate-rows first:mt-0">
      <DocumentItem :document="document" :gid="gid" @refetch="refetch" />
    </div>
  </ContentCard>
</template>

<script setup lang="ts">
import { useDocumentsQuery } from '@/gql/myomr'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
  gid: string
  type: string
  eventId: string
  showNotification?: boolean
}>()

const { refetch, result } = useDocumentsQuery({
  eventId: props.eventId,
  tags: [props.type],
  signable: props.gid,
})

const everythingIsSigned = computed(() => {
  if (!result.value?.documents?.nodes.length) return false
  for (const doc of result.value.documents.nodes) {
    if (
      doc.requireUpload &&
      !!doc.docAcknowledgements &&
      !!doc.docAcknowledgements.length &&
      !doc.docAcknowledgements[0].uploadsUrls.length
    )
      return false
  }
  return true
})
</script>
