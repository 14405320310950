<template>
  <div
    :class="`w-screen h-screen bg-black bg-opacity-50 fixed left-0 top-0 z-50 laptop:pl-64 flex justify-center items-center ${frameClass}`"
    @click.self="clickOutside"
  >
    <div :class="`relative inline-block bg-white max-h-[100%] overflow-y-auto ${wrapperClass} ${showFloatingCloseButton ? 'pr-5' : ''}`">
      <SvgIcon
        v-if="showFloatingCloseButton"
        :path="mdiClose"
        class="absolute right-4 top-4 cursor-pointer text-grey-800"
        :size="24"
        @click.stop="emit('closeDialog', false)"
      />
      <slot name="title">
        <p v-if="title" class="text-2xl p-6 mb-0">{{ title }}</p>
      </slot>
      <slot name="description">
        <p v-if="description" class="text-black px-6 text-opacity-60">{{ description }}</p>
      </slot>
      <slot v-if="hasContent" name="content"> </slot>
      <slot v-if="!hideActions" name="actions">
        <div class="flex justify-end mt-6 px-6 pb-6">
          <button v-if="showCancel" class="btn-secondary-purple-m uppercase" @click="emit('closeDialog', false)">
            {{ cancel || t('cancel') }}
          </button>
          <button
            v-if="showApprove"
            :disabled="disabled"
            class="btn-primary-purple-m ml-4"
            data-test-id="accept-button"
            @click="emit('closeDialog', true)"
          >
            {{ approve || t('approve') }}
          </button>
        </div>
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, useSlots, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { mdiClose } from '@mdi/js'

const { t } = useI18n()
const slots = useSlots()

const hasContent = computed(() => {
  return !!slots['content']
})

const props = withDefaults(
  defineProps<{
    approve?: string
    cancel?: string
    description?: string
    frameClass?: string
    hideActions?: boolean
    persist?: boolean
    showApprove?: boolean
    showCancel?: boolean
    title?: string
    wrapperClass?: string
    disabled?: boolean
    showFloatingCloseButton?: boolean
  }>(),
  {
    approve: undefined,
    cancel: undefined,
    description: undefined,
    frameClass: undefined,
    hideActions: undefined,
    persist: undefined,
    showApprove: true,
    showCancel: true,
    title: undefined,
    wrapperClass: undefined,
    disabled: false,
    showFloatingCloseButton: true,
  },
)

const emit = defineEmits<{
  (e: 'closeDialog', value: boolean): void
}>()

function clickOutside() {
  if (!props.persist) emit('closeDialog', false)
}

onMounted(() => {
  document.documentElement.classList.add('overflow-y-hidden')
})

onUnmounted(() => {
  document.documentElement.classList.remove('overflow-y-hidden')
})
</script>
