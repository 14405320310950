<template>
  <ConfirmModal
    persist
    :approve="t('EmailInvitationQuota.sendDraftInviteesModal.approve', inviteesToSend.length)"
    :cancel="t('EmailInvitationQuota.sendDraftInviteesModal.cancel')"
    @close-dialog="handleCloseDialog"
  >
    <template #title>
      <h1 class="text-headline-m flex items-center p-6 pb-0">
        {{ t('EmailInvitationQuota.sendDraftInviteesModal.title', inviteesToSend.length) }}
      </h1>
    </template>
    <template #content>
      <p class="px-6 pt-4">{{ t('EmailInvitationQuota.sendDraftInviteesModal.description', inviteesToSend.length) }}</p>
    </template>
  </ConfirmModal>
</template>
<script lang="ts" setup>
import type { InvitationQuotaQuery, SendIndividualInvitationsInput } from '@/gql/myomr'
import { useI18n } from 'vue-i18n'
import { handleMutationResponse } from '@/helpers/handleMutationResponseHelper'
import { useSendIndividualInvitationsMutation } from '@/gql/myomr'

const { t } = useI18n()

const props = defineProps<{
  invitationQuota: InvitationQuotaQuery['invitationQuota']
  inviteesToSend: string[]
}>()

const { mutate } = useSendIndividualInvitationsMutation()

const emit = defineEmits(['closeDialog', 'openIncompleteTemplateModal'])

async function handleCloseDialog(shouldSaveUsers: boolean) {
  if (!shouldSaveUsers) return emit('closeDialog')

  if (!props.invitationQuota.messageSubject || !props.invitationQuota.messageBody || !props.invitationQuota.replyTo)
    return emit('openIncompleteTemplateModal')

  const individualInvitationsInput: SendIndividualInvitationsInput = {
    emails: props.inviteesToSend,
    invitationQuotaId: props.invitationQuota.id,
    messageSubject: props.invitationQuota.messageSubject,
    messageBody: props.invitationQuota.messageBody,
    replyTo: props.invitationQuota.replyTo,
  }

  if (props.invitationQuota.cc) individualInvitationsInput.cc = props.invitationQuota.cc

  await mutate(individualInvitationsInput).then((result) => {
    handleMutationResponse(
      t,
      result?.data?.sendIndividualInvitations,
      t('EmailInvitationQuota.manualContactTemplate.notifications.sentInvitations').toString(),
    )
    emit('closeDialog')
  })
}
</script>
