import { ApplicationPolicy } from '@/policies/ApplicationPolicy'

export abstract class EventPolicy extends ApplicationPolicy {
  public static manage(): boolean {
    return this.hasEventsOrQuotaAccess()
  }
}

export enum EventAbilities {
  edit = 'manage',
}
