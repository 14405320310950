<template>
  <TooltipWrapper>
    <template #trigger>
      <ReviewInteractionStatusIcon :type="type" />
    </template>
    <template v-if="text" #content>
      <div class="max-w-xs">{{ text }}</div>
    </template>
  </TooltipWrapper>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { TooltipWrapper } from '@ramp106/omrjs-core-ui'
import ReviewInteractionStatusIcon from './ReviewInteractionStatusIcon.vue'
import { ReviewInteractionType } from './types'

const props = defineProps<{
  type: ReviewInteractionType
  tooltipText?: string
}>()

const { t } = useI18n()

const text = computed(() => {
  let value = ''

  switch (props.type) {
    case ReviewInteractionType.REMINDER:
      value = props.tooltipText || ''
      break
    case ReviewInteractionType.REPLY:
      value = props.tooltipText || t('Reviews.product.review.replyStatusHint')
      break
  }
  return value
})
</script>
