<template>
  <div v-if="event && isSupportedType">
    <h1 class="mt-4">{{ t('EventForm.editEvent') }}</h1>

    <BackLink class="mt-4" :to="backLinkTarget">{{ t('backToOverview') }}</BackLink>

    <div v-if="eventLiveUrl" class="mt-2 card py-6">
      <p class="mb-0 pb-0">
        {{ t('EventForm.digitalMasterclassView.watchEventText') }}
        <a :href="eventLiveUrl" target="_blank">{{ t('EventForm.digitalMasterclassView.watchEventCta') }}</a>
      </p>
    </div>

    <ContentCard v-if="!loading" class="mt-2">
      <template #header>
        <EventItem
          v-if="event.id && event.type && isSupportedType"
          :event="event"
          :show-main-button="false"
          :show-chip="true"
          :show-dropdown="false"
        />
      </template>

      <div class="my-6">
        <TextInput v-if="event.type === 'Masterclass'" v-model="formData.streamingKey" :label="t('EventForm.streamingKey').toString()" />
        <TextInput
          v-model="formData.nameDe"
          :label="t('titleDe') + '*'"
          :error="getValidationErrors(v$.nameDe)"
          @update:model-value="v$.nameDe.$validate"
        />
        <TextInput
          v-model="formData.nameEn"
          :label="t('titleEn') + '*'"
          :error="getValidationErrors(v$.nameEn)"
          @update:model-value="v$.nameEn.$validate"
        />
        <TextInput
          v-model="formData.descriptionDe"
          type="textarea"
          :label="t('EventForm.descriptionDe') + '*'"
          :max-length="750"
          :error="getValidationErrors(v$.descriptionDe)"
          @update:model-value="v$.descriptionDe.$validate"
          @blur="v$.descriptionDe.$validate"
        />
        <TextInput
          v-model="formData.descriptionEn"
          type="textarea"
          :label="t('EventForm.descriptionEn') + '*'"
          :max-length="750"
          :error="getValidationErrors(v$.descriptionEn)"
          @update:model-value="v$.descriptionEn.$validate"
          @blur="v$.descriptionEn.$validate"
        />
        <div v-if="isSideEvent">
          <DateInput v-model="startsAt" :label="t('EventForm.startsAt').toString()" />
          <DateInput v-model="endsAt" :label="t('EventForm.endsAt').toString()" />
          <TextInput
            v-model="formData.companyName"
            :label="t('EventForm.companyName') + '*'"
            :error="getValidationErrors(v$.companyName)"
            @update:model-value="v$.companyName.$validate"
          />
          <TextInput
            v-model="formData.companyWebsite"
            :label="t('EventForm.companyWebsite') + '*'"
            :error="getValidationErrors(v$.companyWebsite)"
            @update:model-value="v$.companyWebsite.$validate"
          />
          <TextInput
            v-model="formData.companyEmail"
            :label="t('EventForm.companyEmail') + '*'"
            :error="getValidationErrors(v$.companyEmail)"
            @update:model-value="v$.companyEmail.$validate"
          />
          <TextInput
            v-model="formData.locationName"
            :label="t('EventForm.locationName') + '*'"
            :error="getValidationErrors(v$.locationName)"
            @update:model-value="v$.locationName.$validate"
          />
          <TextInput
            v-model="formData.locationAddress"
            :label="t('EventForm.locationAddress') + '*'"
            :error="getValidationErrors(v$.locationAddress)"
            @update:model-value="v$.locationAddress.$validate"
          />
          <TextInput v-model="formData.ctaButtonLink" :label="t('EventForm.ctaButtonLink') + '*'" />
          <SelectInput v-model="formData.ctaButtonText" :label="t('EventForm.ctaButtonText').toString()">
            <option v-for="option in ctaButtonTextOptions" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </SelectInput>
        </div>
        <div v-if="isSideEvent" class="pt-8">
          <h2 class="pb-4">
            {{ t('EventForm.selectEventType') }}
          </h2>
          <SelectInput v-model="eventFormat" :label="t('EventForm.selectEventType') + '*'" @change="v$.eventFormat.$validate">
            <option v-for="option in eventFormatOptions.concat(otherOption)" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </SelectInput>
          <TextInput
            v-if="otherEventFormat"
            v-model="formData.eventFormat"
            :label="t('EventForm.otherEventFormat') + '*'"
            @update:model-value="v$.eventFormat.$validate"
          />
          <TextInput v-model.number="formData.maxAttendees" type="number" :label="t('EventForm.maxAttendees').toString()" />
        </div>
        <div>
          <SelectInput
            v-model="formData.language"
            :error="getValidationErrors(v$.language)"
            :label="t('EventForm.language') + '*'"
            @change="v$.language.$validate"
          >
            <option v-for="option in languageOptions" :key="option.value" :value="option.value">{{ option.text }}</option>
          </SelectInput>
        </div>
        <div v-if="event.type == 'DigitalMasterclass'">
          <div class="mt-8">
            <span v-if="!event.videoPresentationUrl || reuploadVideoPresentation">
              <FileUpload
                v-model="formData.videoPresentation"
                :icon="mdiPaperclip"
                :label="t('EventForm.videoPresentation')"
                :hint="t('EventForm.videoPresentationHint')"
                accept="video/mp4,video/*"
                class="mt-4 mb-2"
              />
            </span>
            <AlertBox v-else type="success" :icon-path="mdiPaperclip">
              <template #header>
                {{ t('EventForm.videoPresentation') }}
              </template>
              <div class="flex flex-row justify-between">
                <a v-if="event.videoPresentationUrl" :href="event.videoPresentationUrl">
                  {{ videoPresentationUrlLink }}
                </a>
                <button class="ml-12 btn-secondary-purple-m uppercase" @click="reuploadVideoPresentation = true">
                  {{ t('EventForm.reuploadAction') }}
                </button>
              </div>
            </AlertBox>
          </div>

          <div class="mt-8">
            <span v-if="!event.videoRecordingUrl || reuploadVideoRecording">
              <FileUpload
                v-model="formData.videoRecording"
                :icon="mdiPaperclip"
                :label="t('EventForm.videoRecording')"
                :hint="t('EventForm.videoRecordingHint')"
                accept="video/mp4,video/*,.zip"
                class="mt-4 mb-2"
              />
            </span>
            <AlertBox v-else type="success" :icon-path="mdiPaperclip">
              <template #header>
                {{ t('EventForm.videoRecording') }}
              </template>
              <div class="flex flex-row justify-between">
                <a v-if="event.videoRecordingUrl" :href="event.videoRecordingUrl">
                  {{ videoRecordingUrlLink }}
                </a>
                <button class="ml-12 btn-secondary-purple-m uppercase" @click="reuploadVideoRecording = true">
                  {{ t('EventForm.reuploadAction') }}
                </button>
              </div>
            </AlertBox>
          </div>
          <TimelineClicker
            v-if="event.videoRecordingUrl"
            :src="event.videoRecordingUrl"
            :agenda-timestamps="formData.agendaFormatted || '[]'"
            :presentation-timestamps="formData.presentationFormatted || '[]'"
            @update-agenda="updateAgenda"
            @update-presentation="updatePresentation"
          />
        </div>

        <div class="mt-10">
          <h2 class="pb-3">{{ t('EventForm.interests') }} ({{ formData.interests.length }}/4)</h2>

          <AlertBox v-if="showSelectableInterestLimitAlert" type="warning" class="mt-2">
            <template #header>
              {{ t('EventForm.interestsLimitWarning') }}
            </template>
          </AlertBox>

          <div class="chips-list">
            <SelectableInterest
              v-for="interest in availableInterests"
              :key="interest.id"
              :interest="interest"
              :selected="selected(interest)"
              @toggle-selection="toggleSelection"
            />
          </div>
        </div>
      </div>

      <template #footer>
        <div class="text-right p-4">
          <RouterLink :to="{ name: 'events' }" class="btn-text-purple-m mr-4">
            {{ t('cancel') }}
          </RouterLink>

          <button class="btn-primary-purple-m" :disabled="v$.invalid" @click.stop="updateEvent">
            <span v-if="!saving && uploadProgress == 0">{{ t('saveButtonLabel') }}</span>
            <span v-if="saving && 100 > uploadProgress && uploadProgress > 0">{{ uploadProgress }}% {{ t('EventForm.uploaded') }}</span>
            <span v-if="saving && uploadProgress == 100">{{ t('EventForm.processing') }}</span>
          </button>
        </div>
      </template>
    </ContentCard>

    <div class="mt-16">
      <h1>{{ t('EventForm.DocumentPanelTitle') }}</h1>

      <DocumentPanel v-if="event?.type && event?.id" :gid="event.id" :type="event.type" :event-id="event.id" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import {
  type EventAttributes,
  EventTypeEnum,
  useEventQuery,
  type InterestsQuery,
  useInterestsQuery,
  useUpdateEventMutation,
} from '@/gql/myomr'
import { default as EventItem } from './EventItem.vue'
import { showNotification } from '@/helpers/notificationHelper'
import DocumentPanel from '@/components/DocumentManagement/DocumentPanel.vue'
import BackLink from '@/ui/BackLink.vue'
import * as Sentry from '@sentry/vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { toGlobalId } from '@/helpers/globalIdHelper'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf, maxLength } from '@/services/validators'
import { mdiPaperclip } from '@mdi/js'
import { DateInput, SelectInput, TextInput } from '@ramp106/omrjs-core-ui'
import { getValidationErrors } from '@/helpers/validationHelper'

const route = useRoute()
const i18n = useI18n()
const t = i18n.t

type Interest = InterestsQuery['interests']['nodes'][number]

const uploadProgress = ref(0)

const MAX_DESCRIPTION_LENGTH = 750
const NOTIFICATION_MILLISECONDS = 5000

type FormDataType = Omit<Required<EventAttributes>, 'interests' | 'videoPresentation' | 'videoRecording'> & {
  interests: string[]
  videoPresentation: File | undefined
  videoRecording: File | undefined
}
const formData = reactive<FormDataType>({
  agendaFormatted: '',
  agendaRaw: '',
  companyEmail: '',
  companyName: '',
  companyWebsite: '',
  ctaButtonLink: '',
  ctaButtonText: '',
  descriptionDe: '',
  descriptionEn: '',
  duration: '',
  endsAt: '',
  eventFormat: '',
  interests: [],
  language: 'de',
  locationAddress: '',
  locationName: '',
  maxAttendees: 0,
  nameDe: '',
  nameEn: '',
  presentationFormatted: '',
  presentationRaw: '',
  shuttleService: false,
  startsAt: '',
  streamingKey: '',
  subtitleDe: '',
  subtitleEn: '',
  videoFinal: '',
  videoPresentation: undefined,
  videoRecording: undefined,
  websiteBackground: [],
})

const languageOptions = [
  { value: 'de', text: t('de') },
  { value: 'en', text: t('en') },
] as const

function iso8601ToDateTimeLocal(iso8601: string | undefined | null) {
  if (iso8601 && iso8601.length) {
    const date = new Date(iso8601)
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 16)
  } else {
    return ''
  }
}
const startsAt = computed({
  get: () => iso8601ToDateTimeLocal(formData.startsAt),
  set: (newValue) => (formData.startsAt = new Date(newValue).toISOString()),
})
const endsAt = computed({
  get: () => iso8601ToDateTimeLocal(formData.endsAt),
  set: (newValue) => (formData.endsAt = new Date(newValue).toISOString()),
})
const {
  result: eventResult,
  loading,
  refetch: refetchEvent,
  onResult: onEventResult,
} = useEventQuery({ id: toGlobalId('Event', route.params.eventId as string) })
const event = computed(() => eventResult.value?.eventById)
const isSupportedType = computed(() => (event.value?.type || '') in EventTypeEnum)
const isSideEvent = computed(() => event.value?.type === 'SideEvent')

const rules = {
  companyEmail: { requiredIfSideEvent: requiredIf(isSideEvent) },
  companyName: { requiredIfSideEvent: requiredIf(isSideEvent) },
  companyWebsite: { requiredIfSideEvent: requiredIf(isSideEvent) },
  descriptionDe: { required, maxLength: maxLength(MAX_DESCRIPTION_LENGTH) },
  descriptionEn: { required, maxLength: maxLength(MAX_DESCRIPTION_LENGTH) },
  eventFormat: { requiredIfSideEvent: requiredIf(isSideEvent) },
  language: { required },
  locationAddress: { requiredIfSideEvent: requiredIf(isSideEvent) },
  locationName: { requiredIfSideEvent: requiredIf(isSideEvent) },
  nameDe: { required },
  nameEn: { required },
}
const v$ = useVuelidate(rules, formData)

const ctaButtonTextOptions = [
  { value: 'Register Now', text: 'Register Now' },
  { value: 'Apply Now', text: 'Apply Now' },
  { value: 'Sign In Now', text: 'Sign In Now' },
  { value: 'Buy your ticket now', text: 'Buy your ticket now' },
  { value: 'Get your ticket here', text: 'Get your ticket here' },
  { value: 'Request invitation now', text: 'Request invitation now' },
  { value: 'Get in touch now', text: 'Get in touch now' },
]
const eventFormatOptions = [
  {
    text: t('EventForm.eventTypes.network'),
    value: 'Network',
  },
  {
    text: t('EventForm.eventTypes.learn'),
    value: 'Learn',
  },
  {
    text: t('EventForm.eventTypes.special'),
    value: 'Special',
  },
  {
    text: t('EventForm.eventTypes.inviteOnly'),
    value: 'Invite-Only',
  },
]
const otherOption = {
  text: t('EventForm.eventTypes.other'),
  value: 'Other',
}
const otherEventFormat = computed(() => !eventFormatOptions.map((option) => option.value).includes(formData.eventFormat as string))
const eventFormat = computed({
  get: () => (otherEventFormat.value ? 'Other' : formData.eventFormat),
  set: (newValue) => (formData.eventFormat = newValue),
})

onEventResult((result) => {
  if (result?.data?.eventById) {
    const { interests, ...newData } = result.data.eventById
    Object.assign(formData, newData)
    formData.interests = interests.map((i) => i.id)
  }
})

const videoPresentationUrlLink = computed(() => event.value?.videoPresentationUrl?.split('/').slice(-1)[0] || '')
const reuploadVideoPresentation = ref(false)

const videoRecordingUrlLink = computed(() => event.value?.videoRecordingUrl?.split('/').slice(-1)[0] || '')
const reuploadVideoRecording = ref(false)

function updateAgenda(agenda: string) {
  formData.agendaFormatted = agenda
}
function updatePresentation(presentation: string) {
  formData.presentationFormatted = presentation
}

const MAX_INTERESTS = 4
const showSelectableInterestLimitAlert = ref(false)
const { result: interestsResult } = useInterestsQuery()
const availableInterests = computed(() => interestsResult.value?.interests.nodes || [])
const selected = (interest: Interest) => formData.interests.includes(interest.id)
function toggleSelection({ interest, selected }: { interest: Interest; selected: boolean }) {
  selected ? addInterest(interest) : removeInterest(interest)
}
function addInterest(interest: Interest) {
  if (formData.interests.length < MAX_INTERESTS) {
    formData.interests.push(interest.id)
  } else {
    showSelectableInterestLimitAlert.value = true
    setTimeout(() => (showSelectableInterestLimitAlert.value = false), NOTIFICATION_MILLISECONDS)
  }
}
function removeInterest(interest: Interest) {
  const index = formData.interests.indexOf(interest.id)
  if (index > -1) formData.interests.splice(index, 1)
}

const eventLiveUrl = computed(() => {
  if (event.value?.type != 'DigitalMasterclass') return null

  return event.value?.livestreamUrlWithToken
})

const { mutate, loading: saving } = useUpdateEventMutation({
  context: {
    fetchOptions: {
      useUpload: true,
      onProgress: (progress: ProgressEvent) => {
        uploadProgress.value = Math.round((progress.loaded / progress.total) * 100)
      },
    },
  },
})

async function scrollToFirstError() {
  const errorInputs = document.getElementsByClassName('form-input-error')
  if (errorInputs.length) {
    errorInputs[0].scrollIntoView({ behavior: 'smooth' })
  }
}

async function updateEvent() {
  if (event.value) {
    const isFormValid = await v$.value.$validate()
    if (isFormValid) {
      mutate({ eventId: event.value.id, ...formData })
        .then(() => {
          reuploadVideoPresentation.value = false
          showNotification(t('EventForm.successfulUpdateNotification'), 'success')
        })
        .catch((e) => {
          showNotification(t('EventForm.failedUpdateNotification'), 'error')
          Sentry.captureException(e)
        })
        .finally(() => {
          uploadProgress.value = 0
          refetchEvent()
        })
    } else {
      scrollToFirstError()
    }
  }
}

const backLinkTarget = computed(() => {
  const endsAtString = event.value?.endsAt
  if (endsAtString) {
    const endsAt = new Date(endsAtString)
    if (endsAt < new Date()) {
      return { name: 'events', params: { selector: 'archive' } }
    }
  }
  return { name: 'events' }
})
</script>
