<template>
  <ModalDialog
    ref="modal"
    data-testid="send-reminder-dialog"
    persistent
    max-height="95vh"
    max-width="648px"
    :title="t('Reviews.sendReminderDialog.title')"
    @closed="$emit('closed')"
  >
    <div class="text-body-s mb-6">{{ t('Reviews.sendReminderDialog.text') }}</div>
    <MessageBanner
      class="my-6"
      :headline="t('Reviews.product.review.draftedOrStale.headline')"
      :message="t('Reviews.product.review.draftedOrStale.message', { authorName })"
      type="info"
    />
    <div class="text-label-m mb-1.5">
      {{ t('Reviews.sendReminderDialog.recipientLabel') }}
    </div>
    <ChipBadge class="mb-4" rounded> {{ authorName }} </ChipBadge>
    <div class="text-label-m mb-1.5 flex justify-between items-end">
      <span>{{ t('Reviews.sendReminderDialog.messageLabel') }}</span>
      <button v-if="focused" class="btn-secondary-black-s" @click="insertLink">
        {{ t('Reviews.sendReminderDialog.insertReviewLink') }}
      </button>
      <TooltipWrapper v-else :z-index="60">
        <template #trigger>
          <button class="btn-secondary-black-s" disabled @click="insertLink">
            {{ t('Reviews.sendReminderDialog.insertReviewLink') }}
          </button>
        </template>
        <template #content>
          <div class="max-w-xs">
            {{ t('Reviews.sendReminderDialog.insertReviewLinkHint') }}
          </div>
        </template>
      </TooltipWrapper>
    </div>
    <RichInput
      ref="richInput"
      :key="inputKey"
      v-model="v$.message.$model"
      :error="getValidationErrors(v$.message)"
      hide-controls
      plain-text-only
      @focusin="onFocusIn"
      @focusout="onFocusOut"
    />

    <div class="flex justify-between">
      <CheckBox v-model="v$.copyToSender.$model">{{ t('Reviews.sendReminderDialog.copyToSender') }}</CheckBox>
      <button class="btn-primary-purple-m" :disabled="loading || v$.$invalid" @click="onSubmit">
        {{ t('Reviews.sendReminderDialog.send') }}
      </button>
    </div>
  </ModalDialog>
</template>
<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import TurnDown from 'turndown'
import { ChipBadge, CheckBox, ModalDialog, RichInput, TooltipWrapper, nl2br, MessageBanner } from '@ramp106/omrjs-core-ui'
import useVuelidate, { type ValidationArgs } from '@vuelidate/core'
import { required, maxLength, minLength, containsValidator, withI18nMessage } from '@/services/validators'
import { type ProductById, useCreateSurveyResponseReminderMutation } from '@/gql/reviews'
import { getValidationErrors } from '@/helpers/validationHelper'
import { useMeStore } from '@/stores'
import { showNotification } from '@/helpers/notificationHelper'

type FormData = {
  message: string
  copyToSender: boolean
}

const MSG_MIN_LENGTH = 100
const MSG_MAX_LENGTH = 10000
const REVIEW_LINK_BE_PLACEHOLDER = '%{review_link}'
const REVIEW_LINK_PLACEHOLDER = `(${REVIEW_LINK_BE_PLACEHOLDER})`

const props = defineProps<{
  surveyResponseId: string
  authorName?: string
  product: Pick<ProductById, 'title' | 'slug'>
}>()

defineEmits(['closed'])

const meStore = useMeStore()
const { me } = storeToRefs(meStore)
const modal = ref<InstanceType<typeof ModalDialog> | null>(null)
const richInput = ref<InstanceType<typeof RichInput> | null>(null)
const inputKey = ref(1)
const focused = ref(false)
const { t } = useI18n()
const { loading, mutate: sendReminder } = useCreateSurveyResponseReminderMutation({ clientId: 'reviews' })

const reviewLink = computed(() => `<a href="${REVIEW_LINK_BE_PLACEHOLDER}">${t('Reviews.sendReminderDialog.reviewLinkText')}</a> `)

const formData = reactive<FormData>({
  message: t('Reviews.sendReminderDialog.messageBody', {
    authorName: props.authorName,
    productTitle: props.product.title,
    vendorName: me.value?.partnerCompany?.displayName || '',
    reviewLink: reviewLink.value,
  }).toString(),

  copyToSender: false,
})

const containsLink = withI18nMessage(containsValidator, {
  withArguments: true,
  messagePath: () => 'Reviews.sendReminderDialog.missingReviewLink',
})

const rules = computed<ValidationArgs<FormData>>(() => ({
  message: {
    required,
    minLength: minLength(MSG_MIN_LENGTH),
    maxLength: maxLength(MSG_MAX_LENGTH),
    contains: containsLink(REVIEW_LINK_BE_PLACEHOLDER),
  },
  copyToSender: {},
}))

const v$ = useVuelidate(rules, formData)

function insertLink() {
  richInput.value?.insertHTML(REVIEW_LINK_PLACEHOLDER)
  v$.value.message.$model = v$.value.message.$model.replace(REVIEW_LINK_PLACEHOLDER, reviewLink.value)
  // RichInput does not allow outside model change so we have to rerender component
  inputKey.value = inputKey.value + 1
}

function open() {
  modal.value?.open()
}

function close() {
  modal.value?.close()
}

function onFocusIn() {
  focused.value = true
}

function onFocusOut() {
  setTimeout(() => (focused.value = false), 100)
}

async function onSubmit() {
  const isFormValid = await v$.value.$validate()
  if (!isFormValid) {
    showNotification(t('Reviews.sendReminderDialog.invalidForm'), 'error')
    return
  }

  const turnDown = new TurnDown({
    emDelimiter: '_',
    linkStyle: 'inlined',
    headingStyle: 'atx',
  })

  try {
    await sendReminder({
      input: {
        surveyResponseId: props.surveyResponseId,
        message: turnDown.turndown(nl2br(formData.message)),
        sendCopyToVendor: formData.copyToSender,
      },
    })
    showNotification(t('Reviews.sendReminderDialog.success', { authorName: props.authorName }), 'success', 10000)
    close()
  } catch (e) {
    showNotification(t('Reviews.sendReminderDialog.serverError'), 'error', 10000)
  }
}

defineExpose({ open, close })
</script>
