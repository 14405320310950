<template>
  <div class="flex flex-col gap-y-2 mt-12">
    <div class="flex justify-between items-center">
      <div class="text-body-m-bold pb-1">
        <span v-if="reviewReply?.personalized">{{
          t('Reviews.product.review.reply.sectionTitleWithAuthor', { authorName, productTitle })
        }}</span>
        <span v-else>{{ t('Reviews.product.review.reply.sectionTitle', { productTitle }) }}</span>
      </div>
      <div class="flex gap-x-2">
        <button class="btn-secondary-purple-s flex gap-x-1.5" @click="$emit('edit-reply')">
          <MdiSvg :path="mdiPencilOutline" size="16" />
          {{ t('Reviews.product.review.reply.editButtonText') }}
        </button>
        <button class="btn-secondary-purple-s flex gap-x-1.5" @click="$emit('delete-reply')">
          <MdiSvg :path="mdiTrashCanOutline" size="16" />
          {{ t('Reviews.product.review.reply.deleteButtonText') }}
        </button>
      </div>
    </div>
    <div class="bg-grey-200 p-4" data-testid="text-review-quotes-reply">
      <div class="text-body-m-bold pb-1">
        {{ t('components.TextReviewQuotes.response') }}
      </div>
      <MarkdownContent :md="reviewReply?.reply || ''" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { MarkdownContent, MdiSvg } from '@ramp106/omrjs-core-ui'
import type { SurveyResponseReviewReply } from './types'
import { mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js'

const props = defineProps<{
  productTitle: string
  reviewReply: SurveyResponseReviewReply
}>()

defineEmits(['edit-reply', 'delete-reply'])

const authorName = computed(() => props.reviewReply?.senderName?.split(' ')[0] || null)

const { t } = useI18n()
</script>
