<template>
  <div>
    <FileInput
      :accepted="props.acceptedFileTypes?.join(',') || undefined"
      :label="t('Reviews.product.edit.documents.file.label')"
      :helper-text="uploaderHint"
      :file-name="formData.filename"
      :error="fileValidationErrors"
      @input="onFilePicked"
    />
    <TextInput
      v-for="lang in ALLOWED_LOCALES"
      :key="lang"
      v-model="v$.name[lang].$model"
      :max-length="NAME_MAX_LENGTH"
      :error="getValidationErrors(v$.name[lang])"
      @change="onNameChange"
    >
      <template #label>
        <div class="flex justify-between items-end">
          <span>{{ t('Reviews.product.edit.documents.name.label') }}</span>
          <ChipBadge class="uppercase" rounded>{{ lang }}</ChipBadge>
        </div>
      </template>
    </TextInput>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, reactive } from 'vue'
import { FileInput, TextInput, ChipBadge } from '@ramp106/omrjs-core-ui'
import type { ReviewsProductDocumentEditData } from '@/components/ReviewsManagement/types'
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { buildTranslationFieldsRules, fileMaxSizeRule, fileMimeTypeRule, getValidationErrors } from '@/helpers/validationHelper'
import { ALLOWED_LOCALES } from '@/helpers/translationsHelper'
import { maxLength, minLength } from '@/services/validators'

const NAME_MIN_LENGTH = 3
const NAME_MAX_LENGTH = 80
const MAX_FILE_MB_SIZE = 10

const props = defineProps<{
  value: ReviewsProductDocumentEditData
  acceptedFileTypes?: string[]
}>()
const emit = defineEmits<{
  (e: 'input', value: ReviewsProductDocumentEditData): void
}>()

const { t } = useI18n()

const uploaderHint = computed(() => {
  const acceptedMimeTypes = props.acceptedFileTypes?.map((type) => `${type.split('/')[1]}`).join(', ')
  return `${acceptedMimeTypes} ・ Max. ${MAX_FILE_MB_SIZE} MB`
})

const rules = computed(() => ({
  name: {
    ...buildTranslationFieldsRules(
      {
        minLength: minLength(NAME_MIN_LENGTH),
        maxLength: maxLength(NAME_MAX_LENGTH),
      },
      [ALLOWED_LOCALES[0]],
    ),
    ...buildTranslationFieldsRules(
      {
        minLength: minLength(NAME_MIN_LENGTH),
        maxLength: maxLength(NAME_MAX_LENGTH),
      },
      ALLOWED_LOCALES.slice(1),
    ),
  },
  size: fileMaxSizeRule(t, { megaBytes: MAX_FILE_MB_SIZE }),
  contentType: props.acceptedFileTypes ? fileMimeTypeRule(t, { mimeTypes: props.acceptedFileTypes }) : {},
}))

const formData = reactive<ReviewsProductDocumentEditData>({
  ...props.value,
})

function onNameChange() {
  formData.updated = true
}

const v$ = useVuelidate(rules, formData)

const fileValidationErrors = computed(() => getValidationErrors(v$.value.size) || getValidationErrors(v$.value.contentType))

function onFilePicked(file: File | null) {
  if (!file) {
    return
  }
  formData.file = file
  formData.size = file.size
  formData.contentType = file.type
  formData.filename = file.name
  formData.updated = true
}

watch(
  () => formData,
  () => {
    emit('input', formData)
  },
  { deep: true },
)
</script>
