<template>
  <div class="rounded-full bg-grey-200 border border-solid border-grey-300 text-grey-500 p-1">
    <MdiSvg :path="icon" :size="16" />
  </div>
</template>
<script setup lang="ts">
import { MdiSvg } from '@ramp106/omrjs-core-ui'
import { ReviewInteractionType } from '@/components/ReviewsManagement/Reviews/types'
import { mdiEmailCheckOutline, mdiMessageReplyTextOutline } from '@mdi/js'
import { computed } from 'vue'

const props = defineProps<{
  type: ReviewInteractionType
}>()

const icon = computed(() => {
  let icon = ''
  switch (props.type) {
    case ReviewInteractionType.REMINDER:
      icon = mdiEmailCheckOutline
      break
    case ReviewInteractionType.REPLY:
      icon = mdiMessageReplyTextOutline
      break
  }
  return icon
})
</script>
