<template>
  <div>
    <div
      class="max-h-full w-full laptop:w-64 fixed top-0 left-0 z-40 flex flex-col"
      :class="{
        'h-screen': showMenuContent,
      }"
      @click.stop="showOnMobile = false"
    >
      <div class="flex flex-row items-center bg-black">
        <button class="p-4 text-white flex laptop:hidden" @click.stop="showOnMobile = !showOnMobile">
          <SvgIcon :path="menuIconPath" :size="32" />
        </button>
        <SideBarLogo class="laptop:px-5 py-1 laptop:py-6" />
      </div>
      <div class="flex-grow overflow-y-auto max-w-64 bg-black" :class="{ block: showMenuContent, hidden: !showMenuContent }" @click.stop>
        <HorizontalDivider />
        <SideBarItem v-if="canSeeDashboard" :to="{ name: 'dashboard' }">
          {{ t('SideBar.dashboard') }}
        </SideBarItem>
        <HorizontalDivider />

        <SideBarItem v-if="canManageUsers" :to="{ name: 'userList' }">
          {{ t('SideBar.userManagement') }}
        </SideBarItem>

        <template v-if="me && sidebarItems.length && canSeeContentPages">
          <SideBarSubMenu :label="t('SideBar.contentPages')" :to="'/content/'">
            <SideBarSubMenuItem v-for="item in sidebarItems" :key="item.title" :to="item.to">{{ item.title }}</SideBarSubMenuItem>
          </SideBarSubMenu>
        </template>

        <HorizontalDivider />

        <SideBarSubMenu v-if="canManageEvents || canSeeSpeakers" :label="`Events`" :to="{ name: 'events' }">
          <SideBarSubMenuItem v-if="canManageEvents" :to="{ name: 'events' }" :active-routes="EventActiveRoutes">
            {{ t('SideBar.events') }}
          </SideBarSubMenuItem>
          <HorizontalDivider />
          <SideBarSubMenuItem v-if="canSeeSpeakers" :to="{ name: 'speakerList' }">
            {{ t('SideBar.speakers') }}
          </SideBarSubMenuItem>
        </SideBarSubMenu>

        <HorizontalDivider />
        <SideBarItem v-if="canManageTickets" :to="{ name: 'tickets' }" :active-routes="TicketActiveRoutes">
          {{ t('SideBar.tickets') }}
        </SideBarItem>
        <HorizontalDivider />
        <SideBarItem v-if="canSeeCompany" :to="{ name: 'partnerCompany' }">
          {{ t('SideBar.partnerCompany') }}
        </SideBarItem>
        <HorizontalDivider />
        <SideBarItem v-if="canManageBooths && meStore.boothsVisible" :to="{ name: 'booths' }">
          {{ t('SideBar.booths') }}
        </SideBarItem>
        <HorizontalDivider />

        <SideBarSubMenu v-if="canSeeLeads || canSeeProspectCatalog" :label="t('SideBar.leads')" :to="{ name: 'leads' }">
          <SideBarSubMenuItem v-if="canSeeProspectCatalog" :to="{ name: 'prospectEventsIndex' }">
            {{ t('SideBar.prospectsCatalog') }}
          </SideBarSubMenuItem>
          <HorizontalDivider />
          <SideBarSubMenuItem v-if="canSeeLeads" :to="{ name: 'myleads' }">
            {{ t('SideBar.myleads') }}
          </SideBarSubMenuItem>
        </SideBarSubMenu>
        <HorizontalDivider />
        <ReviewsSideBarSubMenu v-if="canManageReviews" data-testid="sidebar-reviews-button" />
      </div>
      <div
        v-if="me"
        class="py-2 px-4 border-t-[1px] border-t-grey-900 max-w-64 bg-black"
        :class="{ block: showMenuContent, hidden: !showMenuContent }"
        @click.stop
      >
        <div class="flex text-white items-center justify-between">
          <div class="mr-4">
            <p class="text-body-m whitespace-nowrap text-ellipsis overflow-hidden w-[150px]">{{ meStore.userName }}</p>
            <p v-if="me?.partnerCompany" class="text-body-m m-0 p-0 whitespace-nowrap text-ellipsis overflow-hidden w-[150px]">
              {{ me.partnerCompany.displayName || me.partnerCompany.name }}
            </p>
          </div>

          <SideBarMenuDropdown>
            <template #activator="{ open }">
              <div @click.prevent="open">
                <SvgIcon :path="mdiChevronRight" :size="24" class="cursor-pointer" />
              </div>
            </template>
            <template #default>
              <ul class="list-none p-0 m-0 w-[275px] text-black text-headline-xs">
                <li
                  v-for="language in displayedLocales"
                  :key="language.value"
                  class="px-5 py-3 cursor-pointer hover:bg-grey-100"
                  @click="() => uiStore.changeLocale(language.value)"
                >
                  {{
                    t('SideBar.switchToLanguage', {
                      locale: language.text,
                    })
                  }}
                  <flag :iso="language.flag" :squared="false" />
                </li>
                <!-- myomrURL -->
                <li class="px-5 py-3 cursor-pointer hover:bg-grey-100">
                  <MyOmrLink href="/" class="text-black visited:text-black">
                    {{ t('SideBar.backToMyOmr') }}
                  </MyOmrLink>
                </li>
                <li class="px-5 py-3 cursor-pointer hover:bg-grey-100" @click.stop="switchAccount">
                  {{ t('SideBar.switchAccount') }}
                </li>
                <li class="px-5 py-3 cursor-pointer hover:bg-grey-100">
                  <a :href="logoutUrl(locale)" class="text-black visited:text-black">
                    {{ t('SideBar.logout') }}
                  </a>
                </li>
              </ul>
            </template>
          </SideBarMenuDropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useDisplay } from '@ramp106/omrjs-core-ui'
import { useMeStore, useUiStore } from '@/stores'
import { $can } from '@/services/roles'
import { useI18n } from 'vue-i18n'
import { mdiChevronRight } from '@mdi/js'
import type { MeQuery } from '@/gql/myomr'
import ReviewsSideBarSubMenu from '@/components/SideBar/ReviewsSideBarSubMenu.vue'
import SideBarItem from '@/components/SideBar/SideBarItem.vue'
import { mdiMenu, mdiClose } from '@mdi/js'
import { logoutUrl } from '@/config/env'
import { useContentPages } from '@/composables/useContentPages'

const { locale, t } = useI18n()
const { sidebarItems } = useContentPages()

const uiStore = useUiStore()
const meStore = useMeStore()

const props = defineProps<{
  me: MeQuery['me'] | undefined
}>()

const availableLocales = [
  { text: 'English', value: 'en', flag: 'gb' },
  { text: 'Deutsch', value: 'de', flag: 'de' },
]
const { isMobile } = useDisplay()
const showOnMobile = ref(false)
const showMenuContent = computed(() => showOnMobile.value || !isMobile.value)
const menuIconPath = computed(() => (showOnMobile.value ? mdiClose : mdiMenu))

const canSeeDashboard = computed(() => $can('viewDashboard', { for: 'User' }))
const canSeeSpeakers = computed(() => $can('viewSpeakers', { for: 'User' }))
const canSeeProspectCatalog = computed(() => $can('viewProspectCatalog', { for: 'User' }))
const canSeeLeads = computed(() => $can('viewLeads', { for: 'User' }))
const canSeeContentPages = computed(() => $can('viewContentPages', { for: 'User' }))
const canSeeCompany = computed(() => $can('viewCompany', { for: 'User' }))
const canManageBooths = computed(() => props.me && $can('viewBooth', { for: 'User' }))
const canManageEvents = computed(() => props.me && hasChildEvents.value && $can('viewEvents', { for: 'User' }))
const canManageTickets = computed(() => props.me && $can('viewTickets', { for: 'User' }))
const canManageReviews = computed(() => meStore.hasAcceptedReviewsTerms && $can('manageReviews', { for: 'User' }))
const canManageUsers = computed(() => $can('manageUsers', { for: 'User' }))

const EventActiveRoutes = ['eventEdit', 'EventAttendees', 'AttendeeUpload']
const TicketActiveRoutes = ['invitations', 'invitationQuota', 'invitationQuotaCsvUpload']

const displayedLocales = computed(() => availableLocales.filter((locale) => locale.value != uiStore.locale))

const hasChildEvents = computed(() => {
  return props.me?.partnerCompany?.hasChildEvents
})

//const hasQuotas = computed(() => {
//  return !!props.me?.partnerCompany?.hasQuotas
//})

function switchAccount() {
  uiStore.setShowAccountSwitcher(true)
}
</script>
