<template>
  <DropDown v-if="$can('downloadInvitees', { for: invitationQuota })" wrapper-class="w-full">
    <template #activator="{ open }">
      <button class="btn-seconday-mint-m" @click.prevent="open">
        <SvgIcon :path="mdiDownload" :size="16" class="mr-2" />
        {{ t('InvitationQuota.downloadInviteesCSV') }}
      </button>
    </template>
    <template #default>
      <ul class="list-none p-0 m-0 divide-solid divide-y divide-grey-300">
        <li v-for="format in exportFormats" :key="`format-${format}`" class="text-left py-2 px-2 cursor-pointer hover:bg-grey-100">
          <a :href="href(format)" class="text-black visited:text-black flex">
            {{ t(`exportFormats.${format}`) }}
          </a>
        </li>
      </ul>
    </template>
  </DropDown>
</template>

<script lang="ts">
export default { name: 'DownloadInvitees' }
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import type { InvitationQuotaQuery } from '@/gql/myomr'
import { invitationQuotaInviteesDownloadUrl, exportFormats, type exportFormat } from '@/services/ApiService'
import { toTypeAndId } from '@/helpers/globalIdHelper'
import { mdiDownload } from '@mdi/js'
import { $can } from '@/services/roles'
import { DropDown } from '@ramp106/omrjs-core-ui'

const props = defineProps<{
  invitationQuota: InvitationQuotaQuery['invitationQuota']
}>()

const { locale, t } = useI18n()

const invitationQuotaNumericID = computed(() => {
  return (props.invitationQuota && toTypeAndId(props.invitationQuota.id)['id']) || ''
})

const href = computed(
  () => (format: exportFormat) => invitationQuotaInviteesDownloadUrl(invitationQuotaNumericID.value, locale.value, format),
)
</script>
